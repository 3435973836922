<template>
  <div
    :class="{ active: showMenu }"
    class="sidebar is-hidden-print has-background-white-bis"
  >
    <div class="title has-text-centered">
      <div class="logo_modal" @click="$emit('close')">
        <img alt="logo" src="../../assets/images/header/Logo.png"/>
      </div>
      <span>
        <img alt="" src="../../assets/images/xModal.svg" @click="emitToggle"/>
      </span>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'sideBar',
  props: ['icon', 'title'],
  inject: ['$validator'],
  data: function () {
    return {
      active: false,
      showMenu: false
    }
  },
  mounted () {
    this.active = true
  },
  methods: {
    emitToggle () {
      this.showMenu = false
      this.$_.delay(() => {
        this.$emit('toggle', '')
      }, 1000)
    }
  },
  watch: {
    active () {
      if (this.active) {
        this.$_.delay(() => {
          this.showMenu = true
        }, 50)
      }
    },
    $route (to, from) {
      this.emitToggle()
    }
  }
}
</script>

<style lang="scss" scoped>
//noinspection CssUnknownTarget
@import "@/assets/style/screen-size.scss";

.title {
  padding: 34px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  -webkit-box-shadow: -10px 0 5px -2px rgba(0, 0, 0, .4);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.35);
  transition: width .4s ease-in-out;
  -webkit-transition: width .4s ease-in-out;
  overflow-y: scroll;

  -ms-scroll-chaining: none;
  overscroll-behavior: none;
  color: #4a4a4a;

  &.active {
    display: block;
    width: 30%;
    padding: 2em;

    &.is-large {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 530px) {
  .sidebar {
    padding: 0px 30px;
  }
  .logo_modal {
    width: 100px;
  }
}
</style>
