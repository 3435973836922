<template>
  <div class="field">
    <label class="label has-text-left">{{ labelText }}</label>
    <div class="control" :class="{ 'has-icons-left': leftIcon, 'has-icons-right': !hideTickMark }">
      <input v-validate="rule" :type="type" :name="fieldName" :disabled="disabled" v-model="value"
        :placeholder="placeHolder" :class="{ input: true, 'is-danger': errors.has(fieldName) }"
        v-on:input="$emit('input', $event.target.value)" v-on:keyup.enter="$emit('enter')" :data-vv-as="labelText"
        autocomplete="off" />
      <span v-if="leftIcon" class="icon is-small is-left">
        <font-awesome-icon :icon="leftIcon" />
      </span>
      <span v-show="errors.has(fieldName)" class="icon is-small is-right has-text-danger">
        <font-awesome-icon icon="times" />
      </span>
      <span v-show="!errors.has(fieldName) && !hideTickMark && model" class="icon is-small is-right has-text-success">
        <font-awesome-icon icon="check" />
      </span>
      <span v-show="errors.has(fieldName)" class="help is-danger">{{
        errors.first(fieldName)
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DoodleInput',
  inject: ['$validator'],
  props: [
    'labelText',
    'model',
    'rule',
    'placeHolder',
    'fieldName',
    'type',
    'leftIcon',
    'disabled',
    'hideTickMark'
  ],
  data: function () {
    return {
      value: this.model
    }
  },
  watch: {
    model() {
      if (this.value !== this.model) {
        this.value = this.model
      }
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 10px;
}
</style>
