<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <DoodleGeneralForm title="External Libraries"
                        action-text="Add Library"
                       :form="doodleForm"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       @action="formAction"
                       @close="$refs.sideBar.emitToggle()">
      <div class="box has-background-info" v-show="libraries.length>0">
        <div v-for="lib in libraries" :key="lib" class="lib-items has-text-white has-text-weight-semibold">
          <a class="is-link has-text-weight-semibold" @click="removeLib(lib)"><font-awesome-icon icon="times-circle"/></a>
          &nbsp;&nbsp;{{lib}}
        </div>
      </div>
      <DoodleInput field-name="library" label-text="Add Jar from Maven Library" type="text"
                   rule="required|regex:^([0-9a-zA-Z\-\_\.]+:[0-9a-zA-Z\-\_\.]+:[0-9a-zA-Z\-\_\.]+[:]?[0-9a-zA-Z\-\.]+]?)$"
                   :model="doodleForm.library"
                   v-model="doodleForm.library" place-holder="org.apache.commons:commons-lang3:jar:3.9"/>
      <template slot="footer">
        <div class="note">
          <span class="is-underlined">Note:</span>
            <br/>1. Library should be available in a public Maven repository.
            <br/>2. You can search maven artifacts in <a href="http://search.maven.org/" target="_blank" rel="nofollow">http://search.maven.org/</a> or
            <a href="http://mvnrepository.com/" target="_blank" rel="nofollow">http://mvnrepository.com/</a>.
            <br/>3. It should be in a format like - <span class="is-family-code">org.apache.commons:commons-lang3:3.4</span>
        </div>
      </template>
    </DoodleGeneralForm>
  </SideBar>
</template>

<script>
import formMixin from '../assets/javascript/form-mixin'
import DoodleGeneralForm from './Utils/DoodleGeneralForm'
import DoodleInput from './Utils/DoodleInput'
import SideBar from './Utils/SideBar'
import { eventBus } from '../assets/javascript/event-bus'

export default {
  name: 'ideLibraries',
  components: { SideBar, DoodleInput, DoodleGeneralForm },
  mixins: [formMixin],
  inject: ['$validator'],
  props: ['libraries'],
  data: function () {
    return {
      doodleForm: {
        library: null
      }
    }
  },
  methods: {
    formAction () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.libraries.indexOf(this.doodleForm.library) < 0) {
            this.libraries.push(this.doodleForm.library)
          }
          this.doodleForm = { library: null }
        }
      })
    },
    removeLib (lib) {
      this.libraries.splice(this.libraries.indexOf(lib), 1)
    },
    updateIDE () {
      eventBus.$emit('IdeUpdateLibraries', this.libraries)
    }
  }
}
</script>

<style scoped lang="scss">
  .note {
    font-size: 0.8em;
    font-weight: 600;
  }

  .lib-items {
    font-size: 0.9em;
  }
</style>
