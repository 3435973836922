<template>
  <div class="action-close-buttons">
    <button
      class="button is-info has-text-white has-text-weight-semibold action-button my_button"
      @click="$emit('click')"
      type="button"
      v-show="showAction"
    >
      {{ actionText }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ActionCloseButton',
  props: ['actionText', 'showAction']
}
</script>

<style scoped lang="scss">
.action-button {
  margin-right: 1em;
}
.action-close-buttons {
  margin-top: 2em;
  margin-bottom: 2em;
}
.my_button {
  padding: 16px 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #ffffff;
  background: #363739;
  border-radius: 5px;
  border: none;
  width: 100%;
  &:hover {
    background: #363739;
    border: none;
  }
}
@media only screen and (max-width: 1040px) {
  .my_button {
    padding: 9px 17px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .my_button {
    padding: 12px 20px;
    font-size: 16px;
  }
}
</style>
