<template>
  <div class="level">
    <div class="level-item">
      <h6 class="title has-text-grey-dark">{{title}}</h6>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: ['title']
}
</script>

<style scoped lang="scss">

</style>
