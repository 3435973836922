<template>
  <div class="columns is-mobile is-multiline">
    <div class="column is-12 " v-if="title"><PageTitle :title="title" /></div>
    <div
      class="column is-10-tablet is-offset-1-tablet  is-offset-1-desktop"
      v-bind:class="{ 'doodle-display-box': !noBox }"
    >
      <Spinner v-show="showInProgressSection" :message="inProgressMessage" />
      <div v-show="showSuccessSection">
        <slot name="successMessage">
          <DoodleMessage
            :message="successMessage"
            message-class="has-text-success"
          />
        </slot>
      </div>
      <div>
        <div v-show="showMainSection">
          <slot></slot>
        </div>
        <div class="has-text-centered">
          <slot name="errorMessage">
            <DoodleMessage
              :message="errorMessageText"
              message-class="has-text-danger"
            />
          </slot>
          <ActionCloseButton
            :action-text="actionText"
            :show-action="showAction"
            @click="$emit('action')"
            @close="$emit('close')"
            v-if="!hideActionClose"
          />
        </div>
        <div v-show="showMainSection">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Utils/Spinner'
import ActionCloseButton from '@/components/Utils/ActionCloseButtons'
import DoodleMessage from '@/components/Utils/DoodleMessage'
import PageTitle from '@/components/PageTitle'

export default {
  name: 'doodleGeneralForm',
  inject: ['$validator'],
  components: { PageTitle, DoodleMessage, ActionCloseButton, Spinner },
  props: [
    'title',
    'inProgressMessage',
    'successMessage',
    'actionText',
    'form',
    'errorMessageText',
    'inProgress',
    'completed',
    'hideAction',
    'hideActionClose',
    'noBox'
  ],
  data: function () {
    return {
      generalForm: this.form
    }
  },
  computed: {
    showMainSection () {
      return !this.inProgress && !this.completed
    },
    showInProgressSection () {
      return this.inProgress
    },
    showSuccessSection () {
      return !this.inProgress && !this.errorMessageText && this.completed
    },
    showAction () {
      return !this.inProgress && !this.completed && this.hideAction !== true
    }
  }
}
</script>

<style scoped lang="scss">
.is-multiline {
  justify-content: center;
}
.is-12 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.is-10-desktop {
  margin-left: 0px;
  width: 33%;
  padding: unset;
}
</style>
